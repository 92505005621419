<template>
  <div>
    <v-container>
      <p class="SectionTitle">
        <v-btn
          style="margin-right: -10px"
          icon
          fab
          small
          @click="goBack()"
        >
          <v-icon style="font-size: 30px; color: black">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        Detalle de categoría : <span>{{ nombreCategoriaOrigin }}</span>
      </p>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model="nombreCategoria"
            class="text-Poppins"
            label="Nombre de la categoría"
            style="margin-bottom: -10px"
            :disabled="!bModifyCategory"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <!-- <v-text-field
            v-if="categoryId != 'N/A'"
            v-model="montoCategoria"
            class="text-Poppins"
            label="Monto"
            placeholder="$00.00"
            suffix="MXN"
            prefix="$"
            style="margin-bottom: -10px"
            @keypress="isNumber($event)"
            :disabled="!bModifyCategory"
          >
          </v-text-field>
          <v-text-field
            v-else
            v-model="montoCategoria"
            class="input-monto-gastado red--text text-Poppins"
            label="Monto gastado"
            suffix="MXN"
            prefix="$"
            style="margin-bottom: -10px"
            :disabled="!bModifyCategory"
            color="red"
            error
          >
          </v-text-field> -->
          <input-currency-global
            :flagModal="true"
            :bRefreshValue="bRefreshValueCurrency"
            :bDisabled="!bModifyCategory"
            @changeMontoGlobal="changeMontoGlobal"
            :modelValue="montoCategoria"
          />
        </v-col>
        <v-col cols="12">
          <div>
            <v-card
              v-if="categoryId != 'N/A'"
              style="padding: 15px; border-radius: 20px"
            >
              <v-simple-table>
                <tbody style="pointer-events: none">
                  <tr>
                    <td class="text-left">
                      <div class="d-flex" style="margin-top: 15px">
                        <v-progress-linear
                          :value="percentCategory"
                          buffer-value="100"
                          :color="colorCategory"
                          style="
                            height: 20px;
                            width: 100%;
                            border-radius: 40px;
                            margin-top: 5px;
                          "
                        >
                          ${{ spentByCategory }} MXN / ${{
                            montocategoriaBarra
                          }}
                          MXN
                        </v-progress-linear>
                        <p class="ml-2" style="margin-top: 5px">
                          {{ parseFloat(percentCategory).toFixed(2) }}%
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-card style="border-radius: 10px">
            <div>
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead v-if="!bLoading">
                    <tr class="headTable">
                      <th class="text-left">Tipo</th>
                      <th class="text-left">Fecha</th>
                      <th class="text-left">Concepto</th>
                      <th class="text-left">Proveedor</th>
                      <th class="text-left">Cantidad</th>
                      <th class="text-left">U.de medida</th>
                      <th class="text-left">Precio unitario</th>
                      <th class="text-left">Subtotal</th>
                      <th class="text-left">Método de pago</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody v-if="!bLoading">
                    <tr
                      v-for="(item, index) in itemOrdersByCategory"
                      :key="index"
                    >
                      <td>{{ item.sType }}</td>
                      <td>{{ item.tCreated }}</td>
                      <td>
                        {{
                          item.sType == "OC"
                            ? item.sPurchaseName.substr(0, 8)
                            : item.sPurchaseName
                        }}
                      </td>
                      <td>
                        {{
                          item.sSupplierName == null
                            ? "N/A"
                            : item.sSupplierName
                        }}
                      </td>
                      <td>{{ item.dQuantity }}</td>
                      <td>{{ item.sMeasureUnitName }}</td>
                      <td>
                        {{
                          item.sType == "OC"
                            ? "N/A"
                            : "$" + mask(item.dUnitPrice) + "MXN"
                        }}
                      </td>
                      <td>${{ mask(item.dMaterialTotal) }} MXN</td>
                      <td>{{ item.sPaymentMethod }}</td>

                      <td style="white-space: nowrap">
                        <div class="d-flex" v-if="bExpense">
                          <v-btn
                            v-if="item.sType == 'GM'"
                            @click="
                              viewDialogExpenseEdit(
                                item.sPurchaseOrderId,
                                false
                              )
                            "
                            icon
                            color="black"
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn
                            color="black"
                            icon
                            v-else
                            @click="getDetailOC(item.sPurchaseOrderId)"
                          >
                            <v-icon> mdi-eye</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="item.sType == 'GM'"
                            @click="deleteExpense(item)"
                            icon
                            color="#F44336"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    <tr v-show="bEmptyData">
                      <td colspan="10">
                        <div
                          style="
                            width: 100%;
                            height: 200px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <p
                            style="
                              text-align: center;
                              font-family: 'Poppins', sans-serif;
                              font-weight: 600;
                            "
                          >
                            No existen órdenes de compra.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <div v-show="bLoading">
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                    <div class="content-text-loading">
                      <p class="text-loading">Cargando... Espere por favor</p>
                    </div>
                  </div>
                </template>
              </v-simple-table>
              <hr
                style="height: 1px; background-color: #efefef; border: none"
              />

              <div class="footer-table">
                <div style="margin-right: 30px">
                  <p>
                    Filas por página:
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="footer-table-dropdown"
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ iItemsPerPage }}

                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="changeTableCategoryDetail(5)">
                          <v-list-item-title>5</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeTableCategoryDetail(10)">
                          <v-list-item-title>10</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeTableCategoryDetail(25)">
                          <v-list-item-title>25</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeTableCategoryDetail(50)">
                          <v-list-item-title>50</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                </div>
                <div style="margin-right: 15px">
                  <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
                </div>
                <div>
                  <v-btn
                    elevation="0"
                    class="footer-table-buttons"
                    @click="backPage"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    elevation="0"
                    class="footer-table-buttons"
                    @click="nextPage"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- BUTTONS ACTIONS -->
    <v-container class="d-flex justify-end">
      <div class="d-flex justify-end" v-if="categoryId != 'N/A'">
        <v-btn
          x-large
          v-if="!bModifyCategory"
          color="black"
          width="175"
          outlined
          class="text-Poppins"
          @click="bModifyCategory = true"
          style="
            height: 40px;
            font-size: 13px;
            font-weight: 600;
            border-radius: 5px;
          "
          >Modificar
        </v-btn>
        <v-btn
          x-large
          v-if="bModifyCategory"
          color="black"
          width="175"
          outlined
          class="text-Poppins"
          @click="cancelChange()"
          style="
            height: 40px;
            font-size: 13px;
            font-weight: 600;
            border-radius: 5px;
          "
          >Cancelar
        </v-btn>
      </div>

      <div class="d-flex justify-end" v-if="categoryId != 'N/A'">
        <v-btn
          v-if="bModifyCategory"
          :loading="bLoadingSaveCategory"
          x-large
          width="175"
          color="primary"
          class="text-Poppins ml-5"
          style="
            height: 40px;
            color: black;
            box-shadow: 0px 20px 40px #00000029;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 600;
          "
          @click="editCategory"
        >
          Guardar
        </v-btn>
        <div v-if="bExpense">
          <v-btn
            v-if="!bModifyCategory"
            x-large
            width="175"
            color="primary"
            class="text-Poppins ml-5"
            style="
              height: 40px;
              color: black;
              box-shadow: 0px 20px 40px #00000029;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 600;
            "
            @click="viewDialogExpenseAdd(categoryId, true)"
          >
            Añadir gasto
          </v-btn>
        </div>
      </div>
    </v-container>
    <view-dialog-expense
      @close="openDialogExpense = false"
      :openDialogExpense="openDialogExpense"
      :sProjectId="projectId"
      :sCategoryId="categoryId"
      :sCustomExpenseId="sCustomExpenseId"
      :typeBloqued="btypeBloqued"
      :sAddorEditExpense="sAddorEditExpense"
      :bBlockedCategory="bBlockedCategory"
    />
    <view-dialog-order-global
      @close="openDialogOrderGlobal = false"
      :openDialogOrderGlobal="openDialogOrderGlobal"
      :itemDetails="itemConcepto"
    />
  </div>
</template>

<script>
import ViewDialogExpense from "@/components/views/Proyects/DialogExpense";
import ViewDialogOrderGlobal from "@/components/core/DialogOrder";
import InputCurrencyGlobal from "../../core/Input-Currency-Global.vue";

export default {
  name: "DetailsProjects",
  props: {
    openDialogDetails: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  components: {
    ViewDialogExpense,
    ViewDialogOrderGlobal,
    InputCurrencyGlobal,
  },
  data() {
    return {
      bRefreshValueCurrency: false,
      bLoadingSaveCategory: false,
      openDialogExpense: false,
      openDialogOrderGlobal: false,
      dialogAddOrEditCategory: false,
      dialogDetailCategory: false,
      bAddOrEditCategory: null,
      projectId: "",

      bLoading: true,
      bEmptyData: false,

      sCustomExpenseId: "",
      categoryId: "",
      nombreCategoria: "",
      nombreCategoriaOrigin: "",
      montoCategoria: 0,
      montoCategoriaOrigin: 0,
      montocategoriaBarra: "",
      spentByCategory: "",
      percentCategory: "",
      colorCategory: "",

      nombreProyecto: "",
      btypeBloqued: 2,
      loadingButton: false,
      bModifyCategory: false,
      bBlockedCategory: false,
      sAddorEditExpense: false, // false= Add , true= Edit
      residente: "",
      estatus: "",
      presupuestoInicial: "",
      presupuestoFinal: "",
      formatPresIni: 0,
      formatPresFin: 0,

      items: [],
      itemsStatus: [],
      itemOrders: [],
      itemCategories: [],
      itemOrdersByCategory: [],
      itemCategoryById: [],
      itemConcepto: {},

      purchaseOrders: [],
      itemConcepto: {},

      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
    };
  },
  beforeMount() {
    this.projectId = this.$route.params.sProjectId;
    this.categoryId =
      this.$route.params.sCategorytId === "0"
        ? "N/A"
        : this.$route.params.sCategorytId;
    if (this.categoryId === "N/A") {
      this.nombreCategoria = this.$route.params.nameCategory;
      this.montoCategoria =
        this.categoryId == "N/A"
          ? this.$route.params.spent
          : this.$route.params.budget;
    } else {
      this.getDetailCategory();
    }
    this.getExpense();
  },
  methods: {
    goBack() {
      this.$router.push({name:"ProyectsDetail",params:{sProyectId :this.$route.params.sProjectId}})  
    },
    cancelChange() {
      this.bModifyCategory = false;
      this.nombreCategoria = this.nombreCategoriaOrigin;
      this.montoCategoria = this.montoCategoriaOrigin;
    },
    changeMontoGlobal(value) {
      this.montoCategoria = parseFloat(value);
    },
    close() {
      this.$emit("close");
      this.tabs = null;
      (this.iCurrentPage = 1),
        (this.iPageNumber = 1),
        (this.iItemsPerPage = 50),
        (this.numPages = 0),
        (this.totalItems = 0);

      this.nombreProyecto = "";
      this.residente = "";
      this.estatus = "";
      this.presupuestoInicial = "";
      this.presupuestoFinal = "";
      this.itemCategories = [];
      this.openDialogOrderGlobal = false;
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    colorPercent() {
      var color = "grey";

      if (this.percent <= 25.99) {
        color = "green";
      } else if (this.percent >= 26.0 && this.percent <= 49.99) {
        color = "yellow";
      } else if (this.percent >= 50.0 && this.percent <= 75.99) {
        color = "orange";
      } else if (this.percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }
      return color;
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    resultadoBarraLineal() {
      const total = Number(this.presupuestoInicial),
        montoCalculado = Number(this.presupuestoFinal);

      var res = 0;
      if (total > 0 && montoCalculado > 0) {
        var res = (montoCalculado * 100) / total;
      }
      this.percent = res;
    },
    resultadoBarraLinealPorCategoria(presupuestoInicial, presupuestoFinal) {
      const total = Number(presupuestoInicial),
        montoCalculado = Number(presupuestoFinal);
      var res = 0;
      if (presupuestoFinal > 1) {
        res = parseFloat((montoCalculado * 100) / total).toFixed(2);
      }
      // this.percent = res;
      return res;
    },
    colorPercentPorCategoria(percent) {
      percent = Number(percent);

      var color = "grey";

      if (percent <= 25.99) {
        color = "green";
      } else if (percent >= 26.0 && percent <= 50.99) {
        color = "yellow";
      } else if (percent >= 51.0 && percent <= 75.99) {
        color = "orange";
      } else if (percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }

      return color;
    },

    changeResidente(e) {
      this.residente = {
        sFullName: this.residente.sFullName,
        sUserId: e,
      };
    },
    changeEstatus(e) {
      this.estatus = {
        sName: this.estatus.sName,
        sProjectStatusId: e,
      };
    },
    getDetailCategory() {
      if (this.categoryId != "N/A") {
        db.get(`${uri}/api/v1/categories/${this.categoryId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {},
        })
          .then((resp) => {
            this.nombreCategoria = resp.data.category.sCategoryName;
            this.nombreCategoriaOrigin = resp.data.category.sCategoryName;

            this.montoCategoria =
              this.sCategoryId == "N/A"
                ? resp.data.category.dSpent
                : resp.data.category.dBudget;
            this.montoCategoria = parseFloat(this.montoCategoria);
            this.montoCategoriaOrigin =
              this.sCategoryId == "N/A"
                ? resp.data.category.dSpent
                : resp.data.category.dBudget;
            this.montoCategoriaOrigin = parseFloat(this.montoCategoriaOrigin);

            this.montocategoriaBarra =
              this.sCategoryId == "N/A"
                ? this.mask(resp.data.category.dSpent)
                : this.mask(resp.data.category.dBudget);
            this.spentByCategory = this.mask(resp.data.category.dSpent);
            this.percentCategory = this.resultadoBarraLinealPorCategoria(
              resp.data.category.dBudget,
              resp.data.category.dSpent
            );
            this.colorCategory = this.colorPercentPorCategoria(
              this.resultadoBarraLinealPorCategoria(
                resp.data.category.dBudget,
                resp.data.category.dSpent
              )
            );
            this.bModifyCategory = false;
            this.bRefreshValueCurrency = !this.bRefreshValueCurrency;
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },
    // get category detail
    getExpense() {
      if (this.categoryId == "N/A") {
        db.get(`${uri}/api/v1/categories/inactive/${this.projectId}`, {
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((resp) => {
            this.numPages = resp.data.iNumPages;
            this.iPageNumber = resp.data.iNumPages;
            this.totalItems = resp.data.iTotal;
            this.itemOrdersByCategory = resp.data.orders;
            // this.montoCategoria = this.mask(resp.data.dSpent);
            this.montoCategoria = parseFloat(resp.data.dSpent);
            this.bLoading = false;
            this.bEmptyData = this.itemOrdersByCategory.length === 0;
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      } else {
        db.get(`${uri}/api/v1/purchase_order/orders/${this.categoryId}`, {
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((resp) => {
            this.numPages = resp.data.iNumPages;
            this.iPageNumber = resp.data.iNumPages;
            this.totalItems = resp.data.iTotal;
            this.itemOrdersByCategory = resp.data.orders;
            this.bLoading = false;
            this.bEmptyData = this.itemOrdersByCategory.length === 0;
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },

    editCategory() {
      this.bLoadingSaveCategory = true;
      const payload = {
        sName: this.nombreCategoria,
        dBudget: this.montoCategoria,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      db.put(`${uri}/api/v1/categories/${this.categoryId}`, payload, config)
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.bModifyCategory = false;
          this.$store.commit("toggle_alert", alert);
          this.bLoadingSaveCategory = false;

          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoadingSaveCategory = false;

          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    deleteCategory(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sCategoryId,
        route: "/api/v1/categories/" + item.sCategoryId,
        unit: " categoría.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    colorEstatus(estatus) {
      let color = "";
      switch (estatus) {
        case "Borrador":
          color = "#7e7e7e";
          break;

        case "Aprobada":
          color = "#97e6b6";
          break;

        case "Recibida":
          color = "#97e6b6";
          break;

        case "Correcciones":
          color = "#ff7d7d";
          break;

        case "Pagada":
          color = "#ffce00";
          break;

        case "Cancelada":
          color = "#ff7d7d";
          break;

        default:
          break;
      }

      return color;
    },
    viewDialogExpenseAdd(sCategoryId, bBlockedCategory) {
      this.sAddorEditExpense = false; // false= Add
      this.bBlockedCategory = bBlockedCategory;
      this.categoryId = sCategoryId;
      this.sCustomExpenseId = "";
      this.openDialogExpense = true;
    },
    viewDialogExpenseEdit(sCustomExpenseId, bBlockedCategory) {
      this.sAddorEditExpense = true; //true = Edit
      this.bBlockedCategory = bBlockedCategory;
      this.sCustomExpenseId = sCustomExpenseId;
      this.openDialogExpense = true;
    },
    viewDialogOrderGlobal(item) {
      this.openDialogOrderGlobal = true;
      this.itemConcepto = item;
    },
    getDetailOC(sOCId) {
      this.$router.push({
        name: "ProyectDetailCategoryDetailOC",
        params: {  sOCId: sOCId },
      });
    },
    deleteExpense(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sPurchaseOrderId,
        route: "/api/v1/custom_expenses/" + item.sPurchaseOrderId,
        unit: " gasto.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
      // this.$store.commit("refresher", true);
    },
  },
  computed: {
    refresh: function() {
      return this.$store.state.refresh;
    },
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
    validateForm() {
      return (
        this.nombreProyecto !== "" &&
        this.residente !== "" &&
        this.estatus !== ""
      );
    },
    validateFormCategoryes() {
      return this.nombreCategoria.trim() !== "" && this.montoCategoria >= 1;
    },

    bExpense() {
      return !this.$store.state.permissions.includes("Administrator")
        ? this.$store.state.permissions.includes("ExpenseManagement")
        : true;
    },
  },
  watch: {
    iCurrentPage: function() {
      this.getDetailCategory();
    },
    iItemsPerPage: function() {
      this.getDetailCategory();
      this.iCurrentPage = 1;
    },
    refresh: function() {
      this.getDetailCategory();
      this.getExpense();
    },
  },
  components: {
    ViewDialogExpense,
    ViewDialogOrderGlobal,
    InputCurrencyGlobal,
  },
};
</script>

<style>
.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center !important;
  margin-left: 5px !important;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn-sincategorizar[disabled] {
  color: transparent;
}
.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}

.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.v-slide-group__wrapper {
  border: solid 1px white;
  border-bottom-color: #c4c4c4;
  width: auto;
}
</style>
